<template>
    <div class="deposit-container py-4 col-12 col-lg-10 mx-auto">
        <div class="page-title white-color text-center my-5">
            <h1>Депозит</h1>
            <span>Пополни баланс и выиграй!</span>
        </div>

        <div class="row mx-auto">
            <div class="col-12 col-sm-12 col-md-6 px-0" :class="[userData ? 'col-md-5' : 'col-md-12 mx-auto']">
                <div class="w-100 white-color px-3 mb-2">
                    <img :src="moneyIcon" alt="deposit" class="mr-3">
                    <span class="choose-method-txt">Выберите способ пополнения:</span>
                </div>
                <div class="row mx-0 " v-if="depositMethods">
                    <div v-for="method in depositMethods" :key="method.id"
                    class="col-12 col-sm-6 payment-deposit"
                    >
                    <div
                         class="paymethod-label pay-method-itm-style text-center float-left"
                         :class="[method.id == selectedMethod.id ? 'selected' : '']"
                         v-on:click="selectMethod(method)">
                        <img :src="getPictureUrl(method.picture)" :alt="method.name" class="mh-100">
                      <div  class="min-amount-label">
                          <span class="control-label">
                              Мин. {{ method.min_amount }} руб. <br />
                              Макс. {{ method.max_amount }} руб. <br />
                          </span>
                      </div>
                    </div>

                    </div>
                </div>

                <div v-else class="row justify-content-center w-100">
                    <i class="fa fa-spinner fa-spin fa-fw my-5 white-color"></i>
                </div>
            </div>
            <div class="col-12  col-md-6 px-0" v-if="userData" id="deposit-area">
                <span class="gray-color deposit-hint">Минимальное пополнение за раз 100 руб.</span><br>
                <span class="gray-color deposit-hint">Максимальное пополнение за раз 15 000 руб.</span> <br><br>
                <span class="red-color deposit-hint" v-if="amountAlert">Уважаемый клиент, пожалуйста, будьте внимательны, сумма будет изменена. Проверьте сумму на странице оплаты.</span>

                <b-form class="my-4 mx-0 deposit-form" @submit="payInFormSubmit" method="POST" id="deposit-form"
                        :action="$_config.payInUrl">
                    <b-row>
                        <div class="col-4">
                            <label class="col-form-label white-color">Сумма пополнения</label>
                        </div>
                        <div class="col-8 input-group">
                            <input class="form-control mt-1" type="number" v-model="sum" required ref="depositAmount"
                                   id="depositAmount" autocomplete="off" aria-label="payin-amount" name="payin_amount"  placeholder="Сумма в рублях">
                        </div>
                    </b-row>

                    <b-row class="mt-4">
                        <div class="col-4">
                            <label class="col-form-label white-color">Итого к оплате:</label>
                        </div>
                        <div class="col-8 input-group white-color">
                            <span class="mt-1">{{sum}} Руб {{selectedMethod.name ? ', Через: ' + selectedMethod.name : ''}}</span>
                        </div>
                    </b-row>

                    <div class="form-group row">
                        <input type="hidden" v-model="selectedMethod.id" name="method">
                        <input type="hidden" v-model="selectedMethod.module" name="selected_system">
                    </div>

                    <div class="clearfix"></div>
                    <b-col cols="12" class="px-0 col-md-11 mx-auto">
                        <Alert :message="formResponse"></Alert>
                    </b-col>

                    <b-row class="mt-2 text-center mx-0">
                        <div class="col-0 col-sm-4 col-lg-5"></div>
                        <div class="col-12 col-sm-8 col-lg-7">
                            <button class="btn btn-green w-100" type="submit"> Пополнить</button>
                        </div>
                    </b-row>

                    <b-row class="mt-3 text-center mx-0">
                        <div class="col-0 col-sm-4 col-lg-5"></div>
                        <div class="col-12 col-sm-8 col-lg-7">
                            <button class="btn btn-orange w-100"
                                    @click.prevent="$root.$emit('bv::show::modal','ActivatePromocode')">
                                Есть промокод?
                            </button>
                        </div>
                    </b-row>
                </b-form>
            </div>

        </div>

        <activate-promocode></activate-promocode>
    </div>
</template>

<script>
    const moneyIcon = require('@/../public/images/icons/money-icon.png')
    import ActivatePromocode from '@/components/payment/ActivatePromocode'
    import Alert from '@/components/Alert'

    export default {
        name: 'Deposit',
        data() {
            return {
                moneyIcon,
                amountAlert:false,
                sum: '',
                selectedMethod: {
                    id: null,
                    module: '',
                    name: '',
                    min_amount: null,
                    max_amount: null,
                },
                formResponse: null
            }
        },
        components: {
            Alert,
            ActivatePromocode
        },
        computed: {
            userData() {
                return this.$store.getters['userData']
            },
            depositMethods() {
                return this.$store.getters['getAvilDepositMethods']
            }
        },
        methods: {
            getPictureUrl(url) {
                return url
            },
            selectMethod(selected) {
                if (!this.userData) {
                    this.selectedMethod.id = null
                    this.selectedMethod.module = ''
                    this.selectedMethod.name = ''
                    this.$router.push({name: 'login'})
                }
                this.amountAlert = false;
                this.selectedMethod.name = selected.name
                this.selectedMethod.id = selected.id
                this.selectedMethod.module = selected.method_module
                if(selected.method_module == 'adwin'){
                    this.amountAlert = true;
                }
                let elem = document.querySelector("#deposit-area")
                if (elem !== null) {
                    let position = elem.getBoundingClientRect().top + document.documentElement.scrollTop
                    position = parseInt(position)
                    window.scrollTo({
                        top: position,
                        behavior: "smooth"
                    });
                }

                setTimeout(() => {
                    document.getElementById("depositAmount").focus();
                }, 500)

            },
            payInFormSubmit(e) {
                if (!this.userData) {
                    e.preventDefault()
                    this.formResponse = {status: 'error', msg: "Unauthorized"}
                    setTimeout(() => {
                        this.$router.push({name: 'login'})
                    }, 500)
                    return
                }
                let payinAmount = parseFloat(this.$refs.depositAmount.value) || 0
                if (!payinAmount > 0) {
                    e.preventDefault()
                    this.formResponse = {status: 'error', msg: "Пожалуйста, укажите правильую сумму"}
                    return false
                }
                if (!this.selectedMethod.id || !this.selectedMethod.module) {
                    e.preventDefault()
                    this.formResponse = {status: 'error', msg: "Пожалуйстф выберите метод депозита"}
                    return false
                }
                return true
            }
        }
    }
</script>

<style scoped>
    .choose-method-txt,
    .deposit-hint {
        font-size: 14px;
    }
    .red-color{
      color: #e51a2a;
    }
    .deposit-form input {
        border-radius: 30px;
    }

    .deposit-form input:focus {
        border: 1px solid rgb(4, 20, 138);
        box-shadow: 0 0 0 2px rgba(4, 20, 138, 0.5) !important;
    }
    .payment-deposit{
        padding: 10px;
    }
    .paymethod-label img{
        width: 50%;
        object-fit: contain;
    }
    #deposit-area{
      padding-top: 35px;
    }
    .paymethod-label {
        background-color: #fefefe;
        height: 70px;
        width: 100%;
        padding: 5px;
        display: flex;
        justify-content: space-between;
    }
    .min-amount-label{
      font-size: 12px;
      width: 50%;
      display: flex;
      justify-content: right;
      align-items: center;
    }

    .pay-method-itm-style {
        cursor: pointer;
        border-radius: 4px;
    }

    .pay-method-itm-style.selected {
        background-color: #703e1c94;
        color: white;
    }

</style>
